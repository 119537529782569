
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { BCard, BCardHeader, BCardBody, BTable, BRow, BCol } from 'bootstrap-vue';
import { WmsQuotationOrder } from '@/courier/domain/wmsQuotationOrder/WmsQuotationOrder';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import html2pdf from 'html2pdf.js';

@Component({
  name: 'QuotationTemplate',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BTable,
    BRow,
    BCol
  }
})
export default class QuotationTemplate extends Vue {
  @Inject(TYPES.FINDBYID_COMPANY)
  readonly findByIdCompany!: CompanyFindById;
  @Prop({ required: true }) readonly quotation!: WmsQuotationOrder;

  isLoading = false;
  logoImg = localStorage.getItem('urlLogoCompany') as string;
  companyName = '';
  companyPhoneNumber = '';
  companyEmail = '';
  companyZipCode = '';
  companyAddress = '';
  customerName = this.quotation?.customer?.fullName;
  customerAddress = `${this.quotation?.customer?.address?.description}, ${this.quotation?.customer?.address?.cityName}, ${this.quotation?.customer?.address?.countryName}`;
  customerPhoneNumber = this.quotation?.customer?.phone1;
  customerEmail = this.quotation?.customer?.email;
  quotationNumber = this.quotation?.number;
  quotationDate = this.quotation?.createAt.split('T').shift();
  quotationExpirationDate = this.quotation?.dueDate;
  quotationDetails =
    this.quotation?.typeOrder === 'FRQ'
      ? `${this.$t('general.quotationMsg')}`
      : `${this.$t('general.quotationRequestMsg')}`;

  // Informacion de items
  items = this.quotation.lines.map(line => ({
    item: line?.reference?.description,
    quantity: line.quantity
  }));
  fields = [
    { key: 'item', label: `${this.$t('general.items')}` },
    { key: 'quantity', label: `${this.$t('general.quantity')}` }
  ];

  // Informacion de servicios
  mainServices = this.quotation.mainServices.map((service, index) => ({
    service: service?.references[index].reference?.filterBrand,
    quantity: 1,
    price: service.references[index].price
  }));
  additionalServices = this.quotation.additionalServices.map((service, index) => ({
    service: service?.references[index].reference?.filterBrand,
    quantity: 1,
    price: service.references[index].price
  }));
  categoryServices = this.quotation.categories.map((service, index) => ({
    service: service?.references[index].reference?.filterBrand,
    quantity: 1,
    price: service.references[index].price
  }));
  services = [...this.mainServices, ...this.additionalServices, ...this.categoryServices];
  servicesFields = [
    { key: 'service', label: `${this.$t('general.services')}` },
    this.quotation.typeOrder === 'FRQ' && {
      key: 'total',
      label: 'Total',
      formatter: (value: any, key: any, item: any) => `$${item.quantity * item.price}`
    }
  ];

  mounted() {
    // eslint-disable-next-line
    console.log('quotation', this.quotation);
    this.getCompanyInfo();
  }

  get totalItems() {
    return this.items.reduce((total, item) => total + item.quantity, 0);
  }

  get totalPrice() {
    return this.services.reduce((total, service) => total + (service?.price ?? 0) * service?.quantity, 0);
  }

  get documentTitle() {
    if (this.quotation.typeOrder === 'FRR') {
      return `${this.$t('menu.Requests')}`;
    } else {
      return `${this.$t('general.quotation')}`;
    }
  }

  async getCompanyInfo() {
    try {
      this.isLoading = true;
      const companyId = localStorage.getItem('businessId') as string;
      const res: any = await this.findByIdCompany.execute(companyId);
      this.isLoading = false;
      this.companyName = res.businessName;
      this.companyAddress = res.address;
      this.companyPhoneNumber = res.phoneNumber;
      this.companyEmail = res.email;
      this.companyZipCode = res.zipCode;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  exportToPDF(id: string) {
    const config = {
      margin: 0.1,
      pagebreak: { mode: 'avoid-all' },
      filename: `${this.documentTitle} #${this.quotationNumber}`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { dpi: 300, scale: 2, useCORS: true },
      jsPDF: { unit: 'in', format: 'legal', orientation: 'p' }
    };
    html2pdf(document.getElementById(id), config);
  }
}
