import { render, staticRenderFns } from "./QuotationTemplate.vue?vue&type=template&id=30a862d4&scoped=true"
import script from "./QuotationTemplate.vue?vue&type=script&lang=ts"
export * from "./QuotationTemplate.vue?vue&type=script&lang=ts"
import style0 from "./QuotationTemplate.vue?vue&type=style&index=0&id=30a862d4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30a862d4",
  null
  
)

export default component.exports